import type { Response } from '~/type'
import type { AppReadVipMemberRes, GetRebateVipSettingOneByIdRes, ListVipLevelConfigRes, ReadVipMemberRes, VipRes } from '~/type/vip'
import { http } from '~/utils/http'

/**
 * 会员VIP信息-查询
 * @returns
 */
export const appReadVipMember = async () => {
  return await http<Response<AppReadVipMemberRes>>('/app-api/member/vip-member/readVipMember', {
    method: 'post'
  })
}

/**
 * VIP等级管理 - 获取VIP福利明细
 * @returns
 */
export const listVipLevelConfig = async () => {
  // 这返回的data是个数组
  return await http<Response<ListVipLevelConfigRes[]>>('/app-api/member/vip-level-config/listVipLevelConfig', {
    method: 'post'
  })
}

/**
 * 红利领取
 * @param {object} data 红利领取
 * @param {number} data.orderId 活动订单ID:
 * @param {string} data.configId 配置id:
 * @param {number} data.bonusSecondType 红利二级类型--不同活动不同含义:
 * @returns
 */
export const receive = async (data: { configId: number | undefined; bonusType: number; bonusSecondType: number }) => {
  return await http<Response>('/app-api/activity/order/receive', {
    method: 'post',
    body: data
  })
}

/**
 * 会员VIP股东股东申请
 * @param {string} contactInfo 用户联系方式
 * @returns
 */
export const appApplyShareholder = async (data: { contactInfo: string }) => {
  return await http<Response<boolean>>('/app-api/member/vip-shareholder/appApplyShareholder', {
    method: 'post',
    body: data
  })
}

/**
 * vip详情
 * @param {object} params vipId
 * @param {number} params.id ID:
 * @returns
 */
export const vipStatusDetail = async (data: { id: number }) => {
  return await http<Response<VipRes>>('/app-api/activity/detail/vip', {
    method: 'post',
    body: data
  }).then((res) => {
    // 这里可以做额外的数据处理 没有额外处理 不用then
    if (res.data) {
      res.data.limitPoints = Number(res.data.limitPoints)
      res.data.totalPointsOnLevel = Number(res.data.totalPointsOnLevel)
      const appVipUpNeedVOS = res.data.appVipUpNeedVOS
      appVipUpNeedVOS?.forEach((item) => {
        item.upNeedPoints = Number(item.upNeedPoints)
        item.upNeedDeposit = Number(item.upNeedDeposit)
      })
    }
    return res
  })
}

/**
 * 查询VIP上下级返水设置数据详细
 * @param {string} id vipId
 * @param {object} params vipId
 * @param {number} params.id ID:
 * @returns
 */
export const getRebateVipSettingOneById = async (data: { id: number }) => {
  return await http<Response<GetRebateVipSettingOneByIdRes>>('/app-api/activity/rebate-vip-settings/getScopeSettingByVip', {
    method: 'post',
    body: data
  })
}
